<template>
  <div class="info_wrap">
    <div class="base_title">请填写基本信息</div>
    <van-form @submit="onSubmit"
              style="padding:0 6px">
      <van-field v-model="form.truename"
                 center
                 label=""
                 clearable
                 @focus="getInput(1)"
                 @blur="getInput(0)"
                 placeholder="请输入姓名">
      </van-field>

      <!-- :rules="[{ required: true, message: '',trigger :'onChange' }]" -->
      <!-- 学校 -->
      <div class="van-hairline--bottom"
           :class="{active:activeInput==1}"></div>
      <van-field v-model="form.description"
                 name="学校"
                 label=""
                 clearable
                 placeholder="请输入学校"
                 @focus="getInput(2)"
                 @blur="getInput(0)">
        <!-- :rules="[{ required: true, message: '',trigger :'onChange' }]" -->
      </van-field>
      <div class="van-hairline--bottom"
           :class="{active:activeInput==2}"></div>

      <!-- 年级 -->
      <van-field readonly
                 clickable
                 label=""
                 :value="grade_name"
                 placeholder="选择年级"
                 @focus="getInput(3)"
                 right-icon="arrow-down"
                 @click="showPicker = true" />
      <div class="van-hairline--bottom"
           :class="{active:activeInput==3}"></div>
      <van-popup v-model="showPicker"
                 round
                 @click-overlay="cancelGrade"
                 position="bottom">
        <div class="popup_container">
          <van-picker show-toolbar
                      title="选择年级"
                      ref="gradeRef"
                      :columns="gradeList"
                      value-key="grade_name"
                      @cancel="cancelGrade"
                      @confirm="onConfirm">
            <template #title>
              <span class="comfirm_title">
                选择年级
              </span>
            </template>
            <template #confirm>
              <span class="comfirm_btn">
                确定
              </span>
            </template>
          </van-picker>
        </div>
      </van-popup>

      <!--地区 -->
      <van-field readonly
                 clickable
                 label=""
                 :value="dis_name"
                 placeholder="请选择地区"
                 @focus="getInput(5)"
                 right-icon="arrow-down"
                 @click="showDisPicker = true" />
      <div class="van-hairline--bottom"
           :class="{active:activeInput==5}"></div>
      <van-popup v-model="showDisPicker"
                 round
                 position="bottom"
                 @click-overlay="cancelDis">
        <div class="popup_container">
          <van-picker title="选择地区"
                      ref="disRef"
                      show-toolbar
                      :columns="columnsDis"
                      :value-key="level==1?'province_name':'paper_district_name'"
                      @cancel="cancelDis"
                      @confirm="onDisConfirm">
            <template #title>
              <span class="comfirm_title">
                选择地区
              </span>
            </template>
            <template #confirm>
              <span class="comfirm_btn">
                确定
              </span>
            </template>
          </van-picker>
        </div>
      </van-popup>

      <!--高中-科目 -->
      <van-field readonly
                 v-show="dis_name&&level==1"
                 clickable
                 label=""
                 :value="selectSubject"
                 placeholder="选择科目"
                 @focus="getInput(4)"
                 right-icon="arrow-down"
                 @click="showSubject" />
      <div class="van-hairline--bottom"
           v-show="dis_name&&level==1"
           :class="{active:activeInput==4}"></div>
      <van-popup v-model="showSubjectPicker"
                 round
                 @click-overlay="cancelSubject"
                 position="bottom">
        <div class="subject_wrap">
          <div class="title">
            <span @click="cancelSubject">取消</span>
            <span>选择科目</span>
            <span @click="comfirmSubject">确定</span>
          </div>
          <div class="list">
            <div class="sub_item"
                 :class="{'sub_active_item':item.isSelect}"
                 v-for="item in subjectListAble"
                 @click="changSubject(item)"
                 :key="item.subject_id">{{item.subject_name}}</div>
            <!-- 布局占位 -->
            <span class="space_box"></span>
            <span class="space_box"></span>
            <span class="space_box"></span>
          </div>
        </div>
      </van-popup>

      <!-- 初中-城市 -->
      <van-field readonly
                 v-show="level==2"
                 clickable
                 label=""
                 :value="selectCity"
                 placeholder="选择城市"
                 @focus="getInput(7)"
                 right-icon="arrow-down"
                 @click="showCityPicker = true" />
      <div class="van-hairline--bottom"
           v-show="level==2"
           :class="{active:activeInput==7}"></div>
      <van-popup v-model="showCityPicker"
                 round
                 @click-overlay="cancelCity"
                 position="bottom">
        <div class="subject_wrap">
          <div class="title">
            <span @click="cancelCity">取消</span>
            <span>选择城市</span>
            <span @click="comfirmCity">确定</span>
          </div>

          <van-cascader v-model="cascaderValue"
                        title="请选择城市"
                        :show-header="false"
                        :field-names="fieldNames"
                        :options="cityOptions"
                        active-color="#1989fa"
                        :closeable="false"
                        @close="showCityPicker = false"
                        @change="changeCity"
                        @finish="onCityFinish">
          </van-cascader>
        </div>
      </van-popup>

      <!--年份 -->
      <van-field readonly
                 clickable
                 label=""
                 :value="form.graduate_year"
                 placeholder="请选择年份"
                 @focus="getInput(6)"
                 right-icon="arrow-down"
                 @click="showYearPicker = true" />
      <div class="van-hairline--bottom"
           :class="{active:activeInput==6}"></div>
      <van-popup v-model="showYearPicker"
                 round
                 position="bottom"
                 @click-overlay="cancelYear">
        <div class="popup_container">
          <van-picker title="选择毕业年份"
                      show-toolbar
                      ref="yearRef"
                      :columns="yearList"
                      @cancel="cancelYear"
                      @confirm="onYearConfirm">
            <template #title>
              <span class="comfirm_title">
                选择毕业年份
              </span>
            </template>
            <template #confirm>
              <span class="comfirm_btn">
                确定
              </span>
            </template>
          </van-picker>
        </div>
      </van-popup>
      <div style="margin:40px 10px;">
        <van-button block
                    :disabled="disabledBtn"
                    type="info"
                    native-type="submit">完 成</van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import { getGrade, getDis, getPro, getSubject, register } from '@/api/activate.js'
import { getGradeM, getDisM, getCityM, registerM } from '@/api/activateMiddle.js'

export default {
  data () {
    return {
      passwordType: 'password',
      activeInput: 0,
      num: 0,
      form: {
        // truename: '',
        // description: '',
        // edu_grade_id: '',
        // paper_district_id: '',
        // subject_ids: [],
        // graduate_year: ''
      },

      intervalId: 0,
      // disabledBtn: true,
      showPicker: false, //显示年级弹窗
      showSubjectPicker: false,//显示科目弹窗
      showDisPicker: false,//地区弹窗
      showYearPicker: false,
      showCityPicker: false,
      yearList: [],
      yearIndex: 0,

      columnsDis: [], //省份
      disList: [],//地区
      gradeList: [],
      defaultGradeIndex: 0,
      getDis: [],
      defaultDisIndex: 0,
      grade_name: "",//用于显示年级
      dis_name: "",//用于显示地区
      subjectList: [],
      lastSubjectList: [],//未确定前科目
      selectSubject: '', //已选科目
      subjectAble: '',
      level: '1',//1高中，2初中
      selectCity: '',
      cityOptions: [],
      cascaderValue: '',
      fieldNames: {
        text: 'name',
        value: 'city_id',
        children: 'child',
      },
      lastSelectCity: '',
      lastCascaderValue: ''
    }
  },
  watch: {
    subjectAble () {
      // 修改地区科目，清空科目
      this.subjectList.map(item => item.isSelect = false)
      this.lastSubjectList = JSON.parse(JSON.stringify(this.subjectList))
      this.form.subject_ids = []
      this.selectSubject = ''
    }
  },

  computed: {
    disabledBtn () {
      // 全部已填，按钮可点击
      // return this.username && this.password && this.sms && this.phone
      for (let i in this.form) {
        if (i == 'subject_ids' && this.form[i] && this.form[i].length == 0) {
          return true
        }
        if (!this.form[i]) {
          return true
        }
      }
      return false
    },

    // 高中可选科目与地区相关
    subjectListAble () {
      if (!this.subjectAble) {
        return this.subjectList
      }
      let arr = this.subjectAble.split(',')
      let subArr = []
      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          if (this.subjectList.filter(item => item.subject_id == arr[i]).length > 0) {
            subArr.push(...this.subjectList.filter(item => item.subject_id == arr[i]))
          }
        }
        subArr.sort(function (a, b) {
          return a.serial_no - b.serial_no
        })
        return subArr
      }
      return subArr
    }
  },
  beforeCreate () {
    document.title = '天天进步智能学伴'
  },
  mounted () {
    if (this.$route.query.level) {
      this.level = this.$route.query.level
    }

    if (this.level == 1) { //初中不选科目
      this.getSubjectList()
    }
    if (this.level == 2) { //初中选城市
      this.getCityList()
    }

    if (this.level == 1) {
      this.form = {
        sms_record_id: '',
        card_id: '',
        truename: '',
        description: '',
        edu_grade_id: '',
        province_id: '',
        subject_ids: [],
        graduate_year: ''
      }
    } else {
      this.form = {
        sms_record_id: '',
        card_id: '',
        truename: '',
        description: '',
        edu_grade_id: '',
        paper_district_id: '',
        city_id: '',
        city_cn: '',
        graduate_year: ''
      }
    }
    if (this.$route.query.sms_record_id) {
      this.form.sms_record_id = this.$route.query.sms_record_id
    }
    if (this.$route.query.card_id) {
      this.form.card_id = this.$route.query.card_id
    }
    this.getGradeList()
    this.getDisList()
    this.getYear()
  },
  methods: {
    getYear () {
      let today = new Date()
      let toyear = today.getFullYear()
      let toMonth = today.getMonth() + 1
      let toDate = today.getDate()
      let year = ''
      if (this.level == 1) {
        // 高中6月(10日)前默认为上当年，后为下一年
        if (toMonth > 6 || (toMonth == 6 && toDate > 8)) {
          year = toyear + 1
        } else {
          year = toyear
        }
        this.form.graduate_year = year
        for (let i = year; i < year + 4; i++)
          this.yearList.push(i)
      }
      if (this.level == 2) {
        // 初中6月(30日)前默认为上当年，后为下一年
        if (toMonth > 6) {
          year = toyear + 1
        } else {
          year = toyear
        }
        this.form.graduate_year = year
        for (let i = year; i < year + 4; i++)
          this.yearList.push(i)
      }
    },
    // 密码显隐
    switchPasswordType () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    async onSubmit () {
      if (this.level == 1) {
        await register({
          ...this.form
        })
        this.$router.push('/activate/success')
      }
      if (this.level == 2) {
        await registerM({
          ...this.form
        })
        this.$router.push('/activate/success')
      }
    },
    // 当前选中表单项
    getInput (num) {
      this.activeInput = num
    },
    async getGradeList () {
      if (this.level == 1) {
        const { data } = await getGrade()
        this.gradeList = data.list
      } else if (this.level == 2) {
        const { data } = await getGradeM()
        this.gradeList = data.list
      }
    },
    cancelGrade () {
      this.showPicker = false;
      setTimeout(() => {
        this.$refs.gradeRef.setIndexes([this.defaultGradeIndex])
      }, 100);
    },

    onConfirm (value, index) {
      this.form.edu_grade_id = value.grade_id;
      this.grade_name = value.grade_name
      this.showPicker = false;
      this.defaultGradeIndex = index
    },

    async getDisList () {
      if (this.level == 1) {
        const { data } = await getPro()
        this.columnsDis = data.list
        const res = await getDis()
        this.disList = res.data.list
      } else if (this.level == 2) {
        const { data } = await getDisM()
        this.columnsDis = data.list
      }
    },

    async getSubjectList () {
      const { data } = await getSubject()
      this.subjectList = data.list
      // this.subjectList.map(item => {
      //   item.isSelect = false
      // })
    },
    showSubject () {
      this.showSubjectPicker = true;
      this.lastSubjectList = JSON.parse(JSON.stringify(this.subjectList))
    },
    changSubject (item) {
      item.isSelect = !item.isSelect
      this.subjectList.splice() //用来使vue检测到变化
    },
    cancelSubject () {
      this.showSubjectPicker = false;
      this.subjectList = JSON.parse(JSON.stringify(this.lastSubjectList))
      this.subjectList.splice()
    },

    comfirmSubject () {
      let selectedItem = this.subjectList.filter(item => item.isSelect == true)
      let subject_ids = selectedItem.map(item => { return item.subject_id })
      let subject_names = selectedItem.map(item => { return item.subject_name })
      this.form.subject_ids = subject_ids
      this.selectSubject = subject_names.join('，')
      this.showSubjectPicker = false;
    },
    async getCityList () {
      const { data } = await getCityM()
      this.cityOptions = data
    },
    onCityFinish ({ selectedOptions }) {
      // this.showCityPicker = false;
      this.selectCity = selectedOptions.map((option) => option.name).join('，');
    },
    changeCity ({ selectedOptions }) {
      // this.selectCity = selectedOptions.map((option) => option.name).join('，');
    },
    comfirmCity () {
      this.showCityPicker = false
      this.form.city_id = this.cascaderValue
      this.form.city_cn = this.selectCity
      this.lastSelectCity = this.selectCity
      this.lastCascaderValue = this.cascaderValue
    },
    cancelCity () {
      this.showCityPicker = false
      this.selectCity = this.lastSelectCity
      this.cascaderValue = this.lastCascaderValue
    },


    onDisConfirm (value, index) {
      if (this.level == 2) {
        this.form.paper_district_id = value.paper_district_id;
        this.dis_name = value.paper_district_name
        this.showDisPicker = false;
      }

      if (this.level == 1) {
        this.form.province_id = value.province_id;
        this.dis_name = value.province_name
        let dis_value = this.disList.filter(item => item.paper_district_id == value.paper_district_id)
        this.subjectAble = dis_value[0].paper_subjects
        this.showDisPicker = false;
      }
      this.defaultDisIndex = index
    },
    cancelDis () {
      this.showDisPicker = false;
      setTimeout(() => {
        this.$refs.disRef.setIndexes([this.defaultDisIndex])
      }, 100);

    },
    onYearConfirm (value, index) {
      this.form.graduate_year = value;
      this.showYearPicker = false;
      this.yearIndex = index
    },
    cancelYear () {
      this.showYearPicker = false;
      setTimeout(() => {
        this.$refs.yearRef.setIndexes([this.yearIndex])
      })
    }
  }

}
</script>

<style lang="scss" scoped>
$titleSize: 28px;
$titleLineHeight: 33px;

$minSize: 12px;
$minLineHeight: 20px;

$normalSize: 14px;

$subTitleSize: 16px;
$subTitleLineHeight: 24px;
.info_wrap {
  max-width: 600px;
  margin: 0 auto;
}
.base_title {
  font-weight: 500;
  color: #1a1a1a;
  font-size: $titleSize;
  line-height: $titleLineHeight;
  padding: 40px 20px;
}
.van-hairline--bottom {
  width: calc(100% - 24px);
  height: 1px;
  background: #e6e6e6;
  margin: 0 12px;
  display: block;
  margin-bottom: 16px;
}
.active {
  background: #2196f3;
}
.btns_sms {
  background: transparent;
  border: 0;
  color: #2196f3;
}

.subject_wrap {
  min-height: 200px;

  .title {
    padding: 20px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      &:nth-child(1) {
        font-size: $normalSize;
        cursor: pointer;
      }
      &:nth-child(2) {
        font-weight: 700;
        line-height: $subTitleLineHeight;
        font-size: $subTitleSize;
      }
      &:nth-child(3) {
        color: #2196f3;
        font-size: $normalSize;
        cursor: pointer;
      }
    }
  }
  .list {
    padding: 20px 4px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    font-size: $minSize;
    line-height: $minLineHeight;
    // 布局占位
    .space_box {
      box-sizing: border-box;
      padding: 6px 6px;
      min-width: 20%;
      margin-right: 16px;
      // flex: 1;
    }
    .sub_item {
      min-width: 20%;
      width: calc((100% - 64px) / 4);
      margin-right: 15px;
      box-sizing: border-box;
      text-align: center;
      padding: 6px 6px;
      background: #fafafa;
      color: #666666;
      border-radius: 100px;
      margin-bottom: 16px;
      border: 1px solid transparent;
    }
    .sub_active_item {
      border: 1px solid #2196f3;
      background: #e3f2fd;
      color: #2196f3;
    }
  }
}
.popup_container {
  padding: 10px 4px;
  .comfirm_btn {
    color: #2196f3;
    font-size: $normalSize;
  }
  .comfirm_title {
    font-weight: 700;
    font-size: $subTitleSize;
  }
}
::v-deep .van-field__control:read-only {
  text-overflow: ellipsis;
}
::v-deep .van-field__control {
  margin-right: 6px;
}
.van-cell::after {
  display: none;
}
::v-deep .van-picker-column {
  cursor: pointer;
}
::v-deep .van-toast--top {
  top: 10%;
}

@media screen and (min-width: 710px) {
  .info_wrap {
    width: 50%;
  }
  .base_title {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .van-cell {
    font-size: 16px;
    line-height: 22px;
  }
  .van-button--normal {
    padding: 0 15px;
    font-size: 16px;
  }
  ::v-deep .van-popup--bottom {
    max-width: 600px;
    width: 50%;
    left: auto;
  }
}
@media screen and (min-width: 1000px) {
  .info_wrap {
    max-width: 614px;
    width: 50%;
  }
  .base_title {
    font-size: 32px;
    line-height: 60px;
    margin-top: 5vh;
    padding-bottom: 40px;
  }
  .van-cell {
    line-height: 30px;
  }
}
</style>